import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Cookies } from 'react-cookie'

import { TPersonalRootState } from '#reducers/entries/personal'

type TPartnerParametersPayload = {
  partnerParameters: string
}

type TUpdateParsedParameters = {
  parsedParameters: TParsedParameters
}

type TClientNotificationPayload = {
  clientNotificationType: string
}

type TUpdateLoggedInToken = {
  token: string
}

type TUpdateReferer = {
  referer: string
}

type TUpdateStage = {
  stage: number
}

type TParsedParameters = {
  [key: string]: string
}

const cookies = new Cookies()

const handleAppendPartnerParameters = (state: string, payload: string): string => {
  if (state) return `${state}&usefullMobile=${payload}`
  return `usefullMobile=${payload}`
}

export interface IAppInitialState {
  intl: any
  user: any
  stage: number
  partnerParameters: string
  referer: string
  loggedInToken: string
  loading: boolean
  parsedParameters?: TParsedParameters
  clientNotificationType: string
  hasErrorFields: boolean
  partnerGid: TNullable<number>
}
export const initialState: IAppInitialState = {
  intl: {},
  user: {},
  stage: 0,
  partnerParameters: (cookies.get('partnerParameters') as string) || '',
  referer: (cookies.get('referer') as string) || '',
  loggedInToken: '',
  loading: false,
  clientNotificationType: '',
  hasErrorFields: false,
  partnerGid: null
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setPartnerGid: (state, { payload }: PayloadAction<number>) => {
      state.partnerGid = payload
    },
    clientLoginRequest: (state) => {
      state.loading = true
      state.hasErrorFields = false
    },
    clientLoginSuccess: (state, { payload }: PayloadAction<TUpdateLoggedInToken>) => {
      state.loading = false
      state.loggedInToken = payload.token
    },
    clientLoginFailure: (state) => {
      state.loading = false
      state.hasErrorFields = true
    },
    updatePartnerParameters: (state, { payload }: PayloadAction<TPartnerParametersPayload>) => {
      state.partnerParameters = payload.partnerParameters
    },
    appendPartnerParameters: (state, { payload }: PayloadAction<TPartnerParametersPayload>) => {
      state.partnerParameters = handleAppendPartnerParameters(
        state.partnerParameters,
        payload.partnerParameters
      )
    },
    updateLoggedInToken: (state, { payload }: PayloadAction<TUpdateLoggedInToken>) => {
      state.loggedInToken = payload.token
    },
    updateReferer: (state, { payload }: PayloadAction<TUpdateReferer>) => {
      state.referer = payload.referer
    },
    updateParsedParameters: (state, { payload }: PayloadAction<TUpdateParsedParameters>) => {
      state.parsedParameters = payload.parsedParameters
    },
    updateStage: (state, { payload }: PayloadAction<TUpdateStage>) => {
      state.stage = payload.stage
    },
    saveClientNotificationType: (state, { payload }: PayloadAction<TClientNotificationPayload>) => {
      state.clientNotificationType = payload.clientNotificationType
    }
  }
})

export const {
  clientLoginRequest,
  clientLoginSuccess,
  clientLoginFailure,
  updatePartnerParameters,
  appendPartnerParameters,
  updateLoggedInToken,
  updateReferer,
  updateParsedParameters,
  updateStage,
  saveClientNotificationType,
  setPartnerGid
} = appSlice.actions
export default appSlice.reducer
const app = (state: TPersonalRootState): IAppInitialState => state.app
export const appLoggedInTokenSelector = createSelector(app, (app) => app.loggedInToken)
export const currentClientNotificationType = createSelector(
  app,
  (app) => app.clientNotificationType
)
export const hasErrorFields = createSelector(app, (app) => app.hasErrorFields)
